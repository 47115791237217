export const ORGANIZATION_UNIT_TYPES = {
  1: 'Відділення (екіпаж, обслуга)',
  2: 'Взвод (авіаційна ланка)',
  3: 'Рота (батарея)',
  4: 'Батальйон (дивізіон, ескадрилья)',
  5: 'Полк',
  6: 'Бригада',
  7: 'Інший підрозділ',
};

export const ORGANIZATION_TYPES = {
  1: 'Сухопутні війська',
  2: 'Повітряні сили',
  3: 'Військово-морські сили',
  4: 'Десантно-штурмові війська',
  5: 'Сили спеціальних операцій',
  6: 'Сили територіальної оборони',
  7: 'Міністерство оборони',
  8: 'Генеральний штаб Збройних Сил',
  9: 'Військові навчальні заклади',
};

export const SUPPLIERS_TYPES = {
  1: 'Постачальник',
  2: 'Розподільчий центр',
};

export const AGREEMENT_TYPES = {
  1: 'Комплекти',
  2: 'Вода',
};

export const ORDER_STATUSES = {
  1: 'Чернетка',
  2: 'Підписана',
  3: 'Видалена',
  4: 'Розподілена',
  5: 'Частково розподілена',
};

export const SUPPLY_STATUSES = {
  1: 'Активне',
  2: 'Видалене'
};

export const INVOICE_STATUSES = {
  1: 'Чернетка',
  2: 'Підписана постачальником',
  3: 'Підписана отримувачем',
  4: 'Підписана всіма',
  5: 'Скасована',
};

export const ORDER_TYPES = {
  // 1: 'Попередня заявка на харчі (люди)',
  2: 'Поточна заявка на харчі (люди)',
  // 3: 'Попередня заявка на харчі (тварини)',
  4: 'Поточна заявка на харчі (тварини)',
  5: 'Поточна заявка на воду',
};
export const MONTHS_TITLES = {
  1: 'січень',
  2: 'лютий',
  3: 'березень',
  4: 'квітень',
  5: 'травень',
  6: 'червень',
  7: 'липень',
  8: 'серпень',
  9: 'вересень',
  10: 'жовтень',
  11: 'листопад',
  12: 'грудень',
};